import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    background-color:#FAFAFA;
    // font-family: Ubuntu,Arial,Helvetica,Verdana,sans-serif;
    padding-bottom:5rem;
    height:inherit;
    @media(max-width:768px){
        height:auto;
    }
`;
const Title = styled.h2`
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5rem;
    color: inherit;
    font-size: 2.5em;
    font-weight: 600;
    line-height: 1.125;
    word-break: break-word;
    padding-top:3rem;
`;
const Line = styled.hr`
    max-width: 120px;    
    background-color: #ccc;
    border: none;
    display: block;
    height: 1px;
    margin: 1.5rem auto;
`;

const ContentDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: -1rem;
    margin-top: -1rem;
`;
const MainContent = styled.div`
    flex: none;
    width: 50%;
    display: block;
    padding: 1rem;
    font-size: 1.155em;
    line-height: 1.65;
    text-align:center;
    @media(max-width:768px){
        width:100%;
        font-size: 0.855em;
    }
`;
function About() {
  return (
    <div id='about'>
        <Container>
        <Title> about </Title>
        <Line/>
        <ContentDiv>
            <MainContent>
                <p style={{textTransform:'uppercase',lineHeight:'2.35rem',letterSpacing:'0.1rem'}}>
                    AT SPACEWALK AUTOMATION, WE ARE INNOVATING THE FUTURE OF INDUSTRIAL AUTOMATION WITH THE POWER OF 
                    SYSTEM INTEGRATION, DATA ANALYTICS, INTERNET OF THINGS (IOT), MACHINE LEARNING, AND WAREHOUSE 
                    MANAGEMENT SYSTEMS. FROM ENSURING OPTIMAL PRODUCTION FLOW TO MAXIMIZING OPERATIONAL 
                    EFFICIENCY, SPACEWALK AUTOMATION PROVIDES CUTTING-EDGE SOLUTIONS TO SIMPLIFY AND STREAMLINE YOUR 
                    OPERATIONS. 
                </p>
                <p style={{textTransform:'uppercase' ,lineHeight:'2.35rem',letterSpacing:'0.1rem'}}>
                    Its services help customers achieve digital transformation, increase productivity and save costs. 
                    With a team of highly-skilled professionals, Spacewalk Automation provides the most advanced, integrated and comprehensive industrial automation solutions. 
                    Its integrated products allow businesses to better manage their operations, gain insights on crucial data, and analyze performance. 
                    By utilizing modern technologies such as artificial intelligence, machine learning, and data analytics, 
                    Spacewalk Automation is able to provide custom solutions that meet the unique needs of its customers.
                </p>
                <p style={{textTransform:'uppercase' ,lineHeight:'2.35rem',letterSpacing:'0.1rem'}}>
                    DISCOVER HOW OUR COMPREHENSIVE AUTOMATION SOLUTIONS CAN TAKE YOUR BUSINESS TO THE 
                    NEXT LEVEL.
                </p>
            </MainContent>
        </ContentDiv>
        </Container>
    </div>
    
  )
}

export default About;