import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import FactoryIcon from '@mui/icons-material/Factory';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import BannerIcons from "../../Components/BannerIcons";

import React from 'react'
const texts = [
    'OUR',
    'SERVICES'
  ];
function Services() {
  return (
    <div id='services'>
        {/* <BannerIcons texts={texts}/> */}
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#256D85', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #256D85' }}
                // date="2011 - present"
                iconStyle={{ background: '#256D85', color: '#fff' }}
                icon={<IntegrationInstructionsIcon/>}
                style={{marginTop:'7rem'}}
            >
                <h3 className="vertical-timeline-element-title">SYSTEM INTEGRATION</h3>
                <p>
                    THE INTEGRATION OF SAP ERP AND 
                    MICROSOFT AND GOOGLE APPS CAN HELP TO 
                    STREAMLINE BUSINESS PROCESSES AND 
                    REDUCE MANUAL DATA TRANSFER. PROPER 
                    SYSTEM INTEGRATION CAN HELP COMPANIES 
                    IMPROVE CUSTOMER SERVICE, REDUCE COSTS 
                    AND INCREASE PROFITS.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2010 - 2011"
                contentStyle={{ background: '#495579', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #495579' }}
                iconStyle={{ background: '#495579', color: '#fff' }}
                icon={<SettingsInputAntennaIcon />}
            >
                <h3 className="vertical-timeline-element-title">INTERNET OF THINGS (IOT)</h3>
                <p>
                    UTILIZING IOT SERVICE, CUSTOMERS CAN 
                    QUICKLY ACCESS REAL-TIME DATA, BETTER 
                    MANAGE RESOURCES, AND CREATE NEW 
                    AUTOMATED PROCESSES. CUSTOMERS CAN GAIN 
                    INSIGHT INTO THEIR BUSINESS AND 
                    OPERATIONS LIKE NEVER BEFORE, GIVING THEM 
                    THE POWER TO MAKE INFORMED DECISIONS 
                    AND TAKE THEIR BUSINESSES TO THE NEXT 
                    LEVEL!
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2010 - 2011"
                iconStyle={{ background: '#2B4865', color: '#fff' }}
                contentStyle={{ background: '#2B4865', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #2B4865' }}
                icon={<DonutLargeIcon />}
            >
                <h3 className="vertical-timeline-element-title">AI INTEGRATION & DATA ANALYTICS</h3>
                <p>
                    THROUGH AUTOMATION, AI CAN HELP 
                    STREAMLINE PROCESSES, INCREASE 
                    ACCURACY, AND SAVE TIME AND MONEY. BY 
                    COMBINING DATA ANALYSIS WITH AI 
                    ALGORITHMS, BUSINESSES CAN CREATE 
                    DATA-DRIVEN SOLUTIONS THAT PROVIDE 
                    PREDICTIVE INSIGHTS INTO MARKET TRENDS 
                    AND CUSTOMER BEHAVIOR. 
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2010 - 2011"
                iconStyle={{ background: '#16213E', color: '#fff' }}
                contentStyle={{ background: '#16213E', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #16213E' }}
                icon={<FactoryIcon />}
                style={{marginBottom:'5rem'}}
            >
                <h3 className="vertical-timeline-element-title">WAREHOUSE MANAGEMENT SYSTEM</h3>
                <p>
                    THROUGH AUTOMATION, AI CAN HELP 
                    STREAMLINE PROCESSES, INCREASE 
                    ACCURACY, AND SAVE TIME AND MONEY. BY 
                    COMBINING DATA ANALYSIS WITH AI 
                    ALGORITHMS, BUSINESSES CAN CREATE 
                    DATA-DRIVEN SOLUTIONS THAT PROVIDE 
                    PREDICTIVE INSIGHTS INTO MARKET TRENDS 
                    AND CUSTOMER BEHAVIOR. 
                </p>
            </VerticalTimelineElement>
        </VerticalTimeline>
    </div>
  )
}

export default Services;