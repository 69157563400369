import React from 'react'
import styled from 'styled-components';
import logo from '../../Resources/logo_footer.svg';

const MainContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1200px;
    width: 100%;
    color:#fff;
    font-size:1.5em;
    @media(max-width:780px){
        margin-left: auto;
        margin-right: auto;
        box-sizing: content-box;
    }
`;
const MainLeft = styled.div`
    width:35%;
`;
const AddressContent = styled.div`
    font-size:14px;
    line-height:1.8rem;
    margin-left:3rem;
    margin-top:1rem;
    @media(max-width:768px){
        margin-left:0px;
        margin-bottom:3rem;
    }
`;

function FooterTwo() {
  return (
    <MainContainer>
        <MainLeft>
            <img src={logo} style={{maxWidth:'150px',margin:'1rem'}}/>SPACEWALK AUTOMATION
            <AddressContent>
                EJIPURA, KORAMANGALA, BANGALORE, INDIA. 560034 <br/>
                +91-7080094910 <br/> RSHAH@SPACEWALKAUTOMATION.COM <br/>
                WWW.SPACEWALKAUTOMATION.COM
            </AddressContent>
        </MainLeft>
        <div>

        </div>
    </MainContainer>
  )
}

export default FooterTwo