import React from 'react'
import styled from 'styled-components'
import FooterTwo from './FooterTwo';

const FooterCompOne = styled.div`
    background-color:#2B4865;
    height:5rem;
    color:#fff;
    text-align:center;
    font-size: 3rem;
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight:bold;
`;

const FooterCompTwo = styled.div`
    height:23rem;
    margin-bottom:2rem;
    margin-top:2rem;
`; 
function Footer() {
  return (
    <div>
        <FooterCompOne>
            
        </FooterCompOne>
        <FooterCompTwo>
          <FooterTwo/>
        </FooterCompTwo>
    </div>
  )
}

export default Footer