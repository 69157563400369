import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';

const FormItems = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    align-items:center;
    margin:1rem;
    width:100%;
    gap:-5rem;
    font-family:system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    @media(max-width:780px){
      display:flex;
      flex-direction:column;
    }
`;
export default function ContactForm() {
  return (
    <div>
      <Formik
        initialValues={{ name: '', email: '',phone:'',company:'',message:'' }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{maxWidth:'550px',width:'100%'}}>
            {/* Name */}
            <FormItems>
              <label htmlFor="name">Name: </label>
              <Field name="name" style={{backgroundColor:'#E9ECEF',borderColor:'#dee2e6',borderRadius:'0.375rem'}} />
              <ErrorMessage name="name">
                { msg =>
                  <div  style={{ color: 'red' }} >
                    {msg}
                  </div>
                }
              </ErrorMessage>
            </FormItems>
            
            {/* Email */}
            <FormItems>
              <label htmlFor="email">Email: </label>
              <Field name="email" style={{backgroundColor:'#E9ECEF',borderColor:'#dee2e6',borderRadius:'0.375rem'}} />
              <ErrorMessage name="email">
                { msg =>
                  <div  style={{ color: 'red' }} >
                    {msg}
                  </div>
                }
              </ErrorMessage>
            </FormItems>
            
              {/* Phone */}
              <FormItems>
                <label htmlFor="phone">Phone: </label>
                <Field name="phone" style={{backgroundColor:'#E9ECEF',borderColor:'#dee2e6',borderRadius:'0.375rem'}}/>
                <ErrorMessage name="phone">
                  { msg =>
                    <div  style={{ color: 'red' }} >
                      {msg}
                    </div>
                  }
                </ErrorMessage>
              </FormItems>
              
              {/* Company */}
              <FormItems>
                <label htmlFor="company">Company: </label>
                <Field name="company" style={{backgroundColor:'#E9ECEF',borderColor:'#dee2e6',borderRadius:'0.375rem'}} />
                <ErrorMessage name="company">
                  { msg =>
                    <div  style={{ color: 'red' }} >
                      {msg}
                    </div>
                  }
                </ErrorMessage>
              </FormItems>
              
                <FormItems>
                  <label htmlFor="message">Message: </label>
                  <Field name="message" component="textarea" style={{backgroundColor:'#E9ECEF',borderColor:'#dee2e6',borderRadius:'0.375rem'}}/>
                  <ErrorMessage name="message">
                    { msg =>
                      <div  style={{ color: 'red' }} >
                        {msg}
                      </div>
                    }
                  </ErrorMessage>
                </FormItems>
                
            <button type="submit" disabled={isSubmitting} style={{backgroundColor:'#01304d',color:'#fff',width:'30%',height:'2.5rem',borderRadius:'6px'}}>
              SEND
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}