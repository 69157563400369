import './App.css';
import Main from './Screens/Main';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Navigation from './Navigation/Navigation';

import firebaseConfig from './fire/config';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


// firebase config 
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation/>
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/view/requests" element={<Requests />}/> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
