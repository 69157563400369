import React from 'react'
import styled from 'styled-components';
import ContactForm from './ContactForm';

const MainContainer = styled.div`
    background-color: #fff;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom:5rem;
    padding-top:5rem;
`;
function Contact() {
  return (
    <MainContainer id="contact">
        <h2 style={{paddingBottom:'3rem'}}>CONTATCT US</h2>
            <div className="card shadow" style={{borderColor:'#fff',width:'65%'}}>
                <div className="card-body">
                    <ContactForm/>
                </div>
            </div>
    </MainContainer>
  )
}

export default Contact