import React,{useState,useEffect} from "react";
import desktopImage from '../Resources/smart-farm-2.webp';
import mobileImage from '../Resources/Baneer_img.png';
import './component.css'
import styled from "styled-components";
import Lottie from 'react-lottie';
import animationData from '../Components/lotties/animate.json';

const ImageContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 20px;
    border: 0px solid green;
    // background-color: #01304d;
    height: inherit;
    @media(max-width:768px){
        height: fit-content;
    }
`;


export default function IntroCard() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return (
        <ImageContainer id="main">
            <div>
                {
                    windowWidth>768 ? 
                    <>
                        <img src={desktopImage} width={'100%'} alt="Not available"  />
                        {/* <Lottie 
                            options={defaultOptions}
                            height={350}
                            width={1250}
                        /> */}
                    </>
                    :
                    <>
                        <img src={mobileImage} width={'100%'} alt="Not available"  />
                    </>
                }
            </div>
        </ImageContainer>
    )
}