import React from "react";
import IntroCard from "../Components/IntroCard";
import './Main.css'
import Services from "../Components/services/Services";
import About from "../Components/about/About";
import Contact from "../Components/contact/Contact";
import Footer from "../Components/footer/Footer";

export default function Main() {
    return (
        <div className="c-main-container">
            {/* For banner Image */}
            <IntroCard /> 
            {/* For about page */}
            <About/>
            {/* For Services page */}
            <Services/>
            {/* For Contact Page */}
            <Contact/>
            {/* Footer */}
            <Footer/>
        </div>
    )
}