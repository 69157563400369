import React,{ useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import './navigation.css';
import logosw from '../Resources/swlogo.svg';
import styled from 'styled-components';
import { Nav } from 'react-bootstrap';

const LeftMenu = styled.div`
    float: left;
    width: 50%;
`;
const RightMenu = styled.div`
    float: right;
    width: 50%;
    display: flex;
    justify-content: flex-end;
`;

function DrawerAppBar(props) {
  const drawerWidth = 240;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center',height:'64px'}}>
      <Divider />
      <List>
      <Nav.Link href='#main' className='MenuItem'>
        <img src={logosw} className="App-logo" alt="logo" /><br/>
        SPACEWALK AUTOMATION
      </Nav.Link>
      <br/>
        <Nav.Link href='#about' className='MenuItem'>ABOUT</Nav.Link>
        <Nav.Link href='#services' className='MenuItem'>SERVICES</Nav.Link>
        <Nav.Link href='#contact' className='MenuItem'>CONTACT US</Nav.Link>
        <a href='https://daily-update.spacewalkautomation.com/' target={'_blank'} className='MenuItem'>EMPLOYEE PORTAL</a>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex',height:'54px'}}>
      <AppBar component="nav" style={{backgroundColor:'#fff'}}>
        <Toolbar style={{maxWidth:'1250px',marginLeft:'auto',marginRight:'auto',width:'100%'}}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'flex',width:'100%',alignItems:'center' } }} >
            <LeftMenu>
              <Nav.Link href='#main' className='MenuItem'>
                <img src={logosw} className="App-logo" alt="logo" />
                SPACEWALK AUTOMATION
                </Nav.Link>
            </LeftMenu>
            <RightMenu>
                  <Nav.Link href='#about' className='MenuItem'>ABOUT</Nav.Link>
                  <Nav.Link href='#services' className='MenuItem'>SERVICES</Nav.Link>
                  <Nav.Link href='#contact' className='MenuItem'>CONTACT US</Nav.Link>
                  <a href='https://daily-update.spacewalkautomation.com/' target={'_blank'} className='MenuItem'>EMPLOYEE PORTAL</a>
            </RightMenu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default DrawerAppBar;
